export const discounts = [
    {
        year: 2023,
        month: 12,
        element: <>
            <h2>Safe Laser Akció</h2>
            <p>Karácsonyi akció keretében most hatalmas kedvezmény a Safe Laser kezelésekre!</p>
        </>
    },
    {
        year: 2024,
        month: 1,
        element: <> </>
    },
    {
        year: 2024,
        month: 2,
        element: <>
            <h2>Vizes Lábmasszázs Akció</h2>
            <p>50%-os kedvezmény egész hónapban!</p>
        </>
    },
    {
        year: 2024,
        month: 3,
        element: <>
            <h2>Kinesio Tape Akció</h2>
            <p>50%-os kedvezmény egész hónapban!</p>
        </>
    },
    {
        year: 2024,
        month: 4,
        element: <>
            <h2>Japán Körömápolás Akció</h2>
            <p>50%-os kedvezmény egész hónapban!</p>
        </>
    },
    {
        year: 2024,
        month: 5,
        element: <>
            <h2>Pedikűr Akció</h2>
            <p>10%-os kedvezmény egész hónapban!</p>
        </>
    },
    {
        year: 2024,
        month: 6,
        element: <>
            <h2>Talpmasszázs Akció</h2>
            <p>10%-os kedvezmény egész hónapban!</p>
        </>
    },
    {
        year: 2024,
        month: 7,
        element: <>
            <h2>Arcmasszázs Akció</h2>
            <p>10%-os kedvezmény egész hónapban!</p>
        </>
    },
    {
        year: 2024,
        month: 8,
        element: <>
            <h2>Svéd Hátmasszázs Akció</h2>
            <p>10%-os kedvezmény egész hónapban!</p>
        </>
    },
    {
        year: 2024,
        month: 9,
        element: <>
            <h2>Teljes Testmasszázs Akció</h2>
            <p>10%-os kedvezmény egész hónapban!</p>
        </>
    },
    {
        year: 2024,
        month: 10,
        element: <>
            <h2>Teljes Testmasszázs Akció</h2>
            <p>10%-os kedvezmény egész hónapban!</p>
        </>
    },
    {
        year: 2024,
        month: 11,
        element: <>
            <h2>Paraffinos Lábápolás Akció</h2>
            <p>10%-os kedvezmény egész hónapban!</p>
        </>
    },
    {
        year: 2024,
        month: 12,
        element: <>
            <h2>Bérlet Akció</h2>
            <p>+5 Bemer ajándákba bármely bérletre!</p>
        </>
    },
    {
        year: 2024,
        month: 'nonap',
        element: <>
            <h2>Nőnapi Akció</h2>
            <p>Mai napon 50% kedvezmény a Hölgyeknek minden szolgáltatásra!</p>
        </>
    },
    {
        year: 2024,
        month: 'mikulas',
        element: <>
            <h2>Mikulás Akció</h2>
            <p>Mai napon 50% kedvezmény minden szolgáltatásra!</p>
        </>
    },
]