import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { faqs } from '../../data/data';
import { HeadSection, Section } from '../Sections';

import { motion as m } from 'framer-motion';
import { animations } from '../../data/framer-animations';

import szab_01 from "../../img/szabalyzat/szab_01.jfif";
import szab_02 from "../../img/szabalyzat/szab_02.jfif";
import szab_03 from "../../img/szabalyzat/szab_03.jfif";

export default function Hazirend() {
    const activeFAQs = faqs.filter((e) => e.isActive);

    const [heights, setHeights] = useState(activeFAQs.map(() => 0));

    useEffect(() => {

        updateHeights();

        function updateHeights() {
            let paragraphs = [...document.querySelectorAll('.answer p')];
            let newHeights = paragraphs.map(p => p.clientHeight);
            setHeights([...newHeights]);
        }

        window.addEventListener('resize', updateHeights);

        return () => {
            window.removeEventListener('resize', updateHeights);
        }
    }, []);

    return (
        <m.div className="page szabalyzat" {...animations.pageTransition}>
            <Helmet>
                <title>Szabályzat</title>
            </Helmet>
            <Section
                bottom
                top
                content={
                    <div className='box'>
                        <img src={szab_01} alt="szab-1" />
                        <img src={szab_02} alt="szab-2" />
                        <img src={szab_03} alt="szab-3" />
                    </div>
                }
            />
        </m.div>
    )
}
