import szervzonaskezmasszazs from '../img/certificates/new_files/tanusitvany-15.png';
import kismamaMasszazs from '../img/certificates/new_files/tanusitvany-17.png';
import anatomiaKozerthetoenMasszoroknek from '../img/certificates/new_files/tanusitvany-18.png';
import thermoMasszazs from '../img/certificates/new_files/tanusitvany-19.png';
import testtekercseles from '../img/certificates/new_files/tanusitvany-20.png';
import dermatoVakuumReflexologia from '../img/certificates/new_files/tanusitvany-22.png';
import herbalance from '../img/certificates/new_files/tanusitvany-23.png';
import triggerPontTerapia from '../img/certificates/new_files/tanusitvany-24.png';
import kinesioTapeTerapeuta from '../img/certificates/new_files/tanusitvany-25.png';
import frissitoSvedMasszor from '../img/certificates/new_files/tanusitvany-26.png';
import babaMasszazs from '../img/certificates/new_files/tanusitvany-27.png';
import talpcsakraMasszazs from '../img/certificates/new_files/tanusitvany-28.png';
import japanArcmasszazs from '../img/certificates/new_files/tanusitvany-29.png';
import lavakovesMasszazs from '../img/certificates/new_files/tanusitvany-30.png';
import kotoszovetiMasszazs from '../img/certificates/new_files/tanusitvany-31.png';

import arcdiagnosztikaEsreflexzonasArcmasszazs from '../img/certificates/new_files/uj-minicrm-oklevel-arcdiagnosztika.png';
import integraltManualisKorrekciostechnika from '../img/certificates/new_files/uj-minicrm-oklevel-integral-manualis.png';
import reflexzonasTalpmasszazs from '../img/certificates/new_files/uj-minicrm-oklevel-reflexzonas-talpmasszazs.png';
import talpDiagnosztikaEsmeregtelenitoTalpmasszazs from '../img/certificates/new_files/uj-minicrm-oklevel-talp-diag-meregtelenito.png';

import bodhiThaiOlajosTalpmasszazs from '../img/certificates/new_files/uj-minicrm-oklevel-bodhi-thai.png';


export const certs = [
    {
        id: 1,
        isActive: true,
        name: 'Szervzónás kézmasszázs',
        grade: 'tanúsítvány',
        src: szervzonaskezmasszazs,
        alt: 'Szervzónás kézmasszázs technika illusztráció'
    },
    {
        id: 2,
        isActive: true,
        name: 'Kismama masszázs',
        grade: 'tanúsítvány',
        src: kismamaMasszazs,
        alt: 'Kismama masszázs technika illusztráció'
    },
    {
        id: 3,
        isActive: true,
        name: 'Anatómia közérthetően masszőröknek',
        grade: 'tanúsítvány',
        src: anatomiaKozerthetoenMasszoroknek,
        alt: 'Anatómia közérthetően masszőröknek technika illusztráció'
    },
    {
        id: 4,
        isActive: true,
        name: 'Thermo-masszázs',
        grade: 'tanúsítvány',
        src: thermoMasszazs,
        alt: 'Thermo-masszázs technika illusztráció'
    },
    {
        id: 5,
        isActive: true,
        name: 'Testtekercselés',
        grade: 'tanúsítvány',
        src: testtekercseles,
        alt: 'Testtekercselés technika illusztráció'
    },
    {
        id: 6,
        isActive: true,
        name: 'Dermato-vákuum reflexológia',
        grade: 'tanúsítvány',
        src: dermatoVakuumReflexologia,
        alt: 'Dermato-vákuum reflexológia technika illusztráció'
    },
    {
        id: 7,
        isActive: true,
        name: 'Herbalance',
        grade: 'tanúsítvány',
        src: herbalance,
        alt: 'Herbalance technika illusztráció'
    },
    {
        id: 8,
        isActive: true,
        name: 'Trigger pont terápia',
        grade: 'tanúsítvány',
        src: triggerPontTerapia,
        alt: 'Trigger pont terápia technika illusztráció'
    },
    {
        id: 9,
        isActive: true,
        name: 'Kinesio tape terapeuta',
        grade: 'tanúsítvány',
        src: kinesioTapeTerapeuta,
        alt: 'Kinesio tape terapeuta technika illusztráció'
    },
    {
        id: 10,
        isActive: true,
        name: 'Frissítő /Svéd/ masszőr',
        grade: 'tanúsítvány',
        src: frissitoSvedMasszor,
        alt: 'Frissítő /Svéd/ masszőr technika illusztráció'
    },
    {
        id: 11,
        isActive: true,
        name: 'Baba masszázs',
        grade: 'tanúsítvány',
        src: babaMasszazs,
        alt: 'Baba masszázs technika illusztráció'
    },
    {
        id: 12,
        isActive: true,
        name: 'Talpcsakra masszázs',
        grade: 'tanúsítvány',
        src: talpcsakraMasszazs,
        alt: 'Talpcsakra masszázs technika illusztráció'
    },
    {
        id: 13,
        isActive: true,
        name: 'Arcdiagnosztika és reflexzónás arcmasszázs',
        grade: 'tanúsítvány',
        src: arcdiagnosztikaEsreflexzonasArcmasszazs,
        alt: 'Arcdiagnosztika és reflexzónás arcmasszázs technika illusztráció'
    },
    {
        id: 14,
        isActive: true,
        name: 'Integrált manuális korrekciós technika',
        grade: 'tanúsítvány',
        src: integraltManualisKorrekciostechnika,
        alt: 'Integrált manuális korrekciós technika illusztráció'
    },
    {
        id: 15,
        isActive: true,
        name: 'Reflexzónás talpmasszázs',
        grade: 'tanúsítvány',
        src: reflexzonasTalpmasszazs,
        alt: 'Reflexzónás talpmasszázs technika illusztráció'
    },
    {
        id: 16,
        isActive: true,
        name: 'Talp-diagnosztika és méregtelenítő talpmasszázs',
        grade: 'tanúsítvány',
        src: talpDiagnosztikaEsmeregtelenitoTalpmasszazs,
        alt: 'Talp-diagnosztika és méregtelenítő talpmasszázs technika illusztráció'
    },
    {
        id: 17,
        isActive: true,
        name: 'Bodhi Thai olajos talpmasszázs',
        grade: 'tanúsítvány',
        src: bodhiThaiOlajosTalpmasszazs,
        alt: 'Bodhi Thai olajos talpmasszázs technika illusztráció'
    },
    {
        id: 18,
        isActive: true,
        name: 'Japán Arcmasszázs',
        grade: 'tanúsítvány',
        src: japanArcmasszazs,
        alt: 'Japán Arcmasszázs'
    },
    {
        id: 19,
        isActive: true,
        name: 'Lávaköves masszázs',
        grade: 'tanúsítvány',
        src: lavakovesMasszazs,
        alt: 'Lávaköves masszázs'
    },
    {
        id: 20,
        isActive: true,
        name: 'Kötőszöveti-reflexzónás mézmasszázs',
        grade: 'tanúsítvány',
        src: kotoszovetiMasszazs,
        alt: 'Kötőszöveti-reflexzónás mézmasszázs'
    }
];